import { Theme, SystemCssProperties } from 'theme-ui'

const fontStack =
  '"nimbus", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'

const colors = {
  white: 'white',
  black: 'black',
  grays: {
    '100': 'hsla(240, 0%, 100%, 1)',
    '200': 'hsla(240, 1%, 90%, 1)',
    '300': 'hsla(240, 1%, 39%, 1)',
    '400': 'hsla(240, 2%, 22%, 1)',
    '500': 'hsla(240, 4%, 18%, 1)',
    '600': 'hsla(240, 0%, 0%, 1)'
  },
  blues: {
    '100': 'hsla(235, 30%, 90%, 1)',
    '200': 'hsla(235, 50%, 80%, 1)',
    '300': 'hsla(235, 58%, 69%, 1)',
    '400': 'hsla(225, 80%, 66%, 1)',
    '500': 'hsla(225, 62%, 36%, 1)',
    '600': 'hsla(235, 60%, 20%, 1)'
  },
  reds: {
    '100': 'hsla(10, 30%, 90%, 1)',
    '200': 'hsla(10, 50%, 80%, 1)',
    '300': 'hsla(10, 67%, 64%, 1)',
    '400': 'hsla(10, 76%, 50%, 1)',
    '500': 'hsla(10, 80%, 35%, 1)',
    '600': 'hsla(10, 60%, 18%, 1)'
  }
}

const themeColors = {
  ...colors,
  text: {
    subtle: colors.grays[200],
    body: colors.grays[100],
    heading: colors.grays[500]
  },
  background: colors.white,
  primary: colors.blues[500],
  secondary: colors.reds[400],
  error: colors.reds[500],
  border: `transparent`
}

export interface CustomTheme extends Theme {
  space:
    | (string | number)[]
    | {
        [K: string]: string | number | (string | number)[]
      }
  alerts?:
    | SystemCssProperties[]
    | {
        [K: string]: SystemCssProperties | SystemCssProperties[]
      }
    | undefined
}

const theme: CustomTheme = {
  breakpoints: ['49em', '54em', '64em'],
  space: {
    '0': 0,
    '1': 4,
    '2': 8,
    '3': 12,
    '4': 16,
    '5': 32,
    '6': 64,
    '7': 128,
    '8': 256,
    '9': 512
  },
  radii: [0, 3, 4, 6, 16, 26, 32],
  fonts: {
    body: fontStack,
    heading: fontStack
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 400
  },
  lineHeights: {
    body: 1.35,
    heading: 1.125
  },
  useColorSchemeMediaQuery: false,
  colors: themeColors,
  buttons: {
    primary: {
      color: 'white',
      bg: 'black',
      borderRadius: 4,
      borderColor: 'border',
      borderWidth: '1px',
      fontWeight: 'heading',
      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed'
      }
    },
    dark: {
      color: 'white',
      bg: 'primary',
      borderRadius: 4,
      borderColor: 'border',
      borderWidth: '1px',
      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed'
      }
    },
    secondary: {
      color: 'white',
      bg: 'black',
      borderRadius: 4,
      borderColor: 'border',
      borderWidth: '1px',
      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed'
      }
    },
    round: {
      bg: 'black',
      color: 'white',
      borderWidth: 0,
      borderColor: 'transparent',
      '&:hover': {
        bg: 'pink'
      },
      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed'
      }
    }
  },
  messages: {
    error: {
      bg: colors.reds[100],
      borderLeftColor: colors.reds[400],
      color: 'white'
    },
    info: {
      bg: colors.blues[100],
      color: 'text.body'
    }
  },
  alerts: {
    error: {
      color: 'white',
      bg: 'error'
    }
  },
  text: {
    small: {
      fontSize: 1
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.025em',
      fontWeight: 'heading'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'text.body'
    },
    h1: {
      color: 'text.heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 6
    },
    h2: {
      color: 'text.heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'text.heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'text.heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text.heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text.heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text.body',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    a: {
      color: 'inherit'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      display: 'block',
      maxWidth: '100%'
    }
  }
}

// Aliases
theme.space.gutter = theme.space[2]
theme.space.xxsmall = theme.space[1]
theme.space.xsmall = theme.space[2]
theme.space.small = theme.space[3]
theme.space.medium = theme.space[4]
theme.space.large = theme.space[5]
theme.space.xlarge = theme.space[6]
theme.space.xxlarge = theme.space[7]

export default theme
