// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-blind-tsx": () => import("./../../../src/pages/blind.tsx" /* webpackChunkName: "component---src-pages-blind-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-multi-2-tsx": () => import("./../../../src/pages/multi2.tsx" /* webpackChunkName: "component---src-pages-multi-2-tsx" */),
  "component---src-pages-multi-tsx": () => import("./../../../src/pages/multi.tsx" /* webpackChunkName: "component---src-pages-multi-tsx" */),
  "component---src-pages-speed-tsx": () => import("./../../../src/pages/speed.tsx" /* webpackChunkName: "component---src-pages-speed-tsx" */)
}

